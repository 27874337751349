import React from "react";

import './styles.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Categoria from "./components/Categoria/Categoria";
import Articulo from "./components/Articulo/Articulo";
import Search from "./components/Search/Search";
import NotA404 from './components/Assets/NotA404';
import Registrarme from "./components/Registrarme/Registrarme";
import Carga from "./components/Back/Carga";
import Editando from "./components/Back/Editando";
import Contacto from "./components/Contacto/Contacto";
import Empresas from "./components/Empresas/Empresas";


const App = () => {

  return (
    <div className='appContainer'>
      <BrowserRouter>
          <header>
            <NavBar />
          </header>
          <main>
            <Routes>
              <Route path='/' element={ <Home /> }/>
              <Route path='/:categoriaId' element={ <Categoria /> }/>
              <Route path='/:categoryId/:articuloId' element={ <Articulo />}/>
              <Route path='/search' element={ <Search />}/>
              <Route path="/newsletter" element={ <Registrarme /> }/>
              <Route path="/empresas" element={ <Empresas /> } />
              <Route path='/carga' element={ <Carga /> }/>
              <Route path='/editando' element={ <Editando /> } />
              <Route path='/contacto' element={ <Contacto /> } />
              <Route path='*' element={ <NotA404 /> }/>
            </Routes>
          </main>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;