import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { getArticulos, getCategorias } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore'
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import RegistrarmeBanner from "../RegistrarmeBanner/RegistrarmeBanner";
import Autor from "../Autor/Autor";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";

const Categoria = () => {
    const [sumar, setSumar] = useState(8);
    const { categoriaId } = useParams();
    const { isLoading, articulos, error } = useFirestore(() => getArticulos());
    const { categorias } = useFirestore(() => getCategorias())

    useEffect(() => {
        window.scrollTo(0, 0);
        logEvent(analytics, window.location.pathname);
    }, []);

    const incrementCount = () => {
        setSumar(sumar + 8);
    };

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <NotA404 />;
    }

    const articuloFiltrado = articulos.filter(e => e.categoria === categoriaId && e.activo === "1")
        .sort((a, b) => a.date > b.date ? -1 : 1);

    const labelCategoria = categorias.filter(e => e.id === categoriaId)

    const cantidadArticulos = articulos.length;

    return (
        <section>
            <div className="categoria">
                { labelCategoria.map(cat =>
                    <h2 key={cat.id}>
                        {cat.label.toUpperCase()}
                    </h2>    
                )}
            </div>
            <div className="container-categoria">
                {articuloFiltrado.slice(0, sumar).map(prod => (
                    <Link to={`/${prod.categoria}/${prod.id}`} className="card-categoria" key={prod.id}>
                        <img src={`../../img/articulos/${prod.id}/img1.webp`} alt={`${prod.titular}`} />
                        <article>
                            {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                            {prod.titular && <h3>{prod.titular}</h3>}
                        </article>
                        <Autor {...prod} />
                    </Link>
                ))}
            </div>
            {cantidadArticulos > sumar && (
                <div className="container-btn">
                    <button onClick={incrementCount} className="btn">LOAD +</button>
                </div>
            )}
            <RegistrarmeBanner />
        </section>
    );
};

export default Categoria;
