import React from "react";
import { Link } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore'

import Autor from "../../Autor/Autor";
import Loading from "../../Assets/Loading";
import NotA404 from "../../Assets/NotA404";

const Home1 = () => {
    const { isLoading, articulos, error } = useFirestore(() => getArticulos());

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <NotA404 />;
    }

    const filteredArticles = articulos
        .filter(e => e.home1 === "1" && e.activo === "1")
        .sort((a, b) => b.date - a.date)
        .slice(0, 1);

    const img = window.innerWidth >= 1024 ? "main-home" : "img2";

    return (
        <div className="home categoria1">
            {filteredArticles.map(prod => (
                <div key={prod.id}>
                    <Link to={`/${prod.categoria}/${prod.id}`} className="link">
                        <img src={`../../img/articulos/${prod.id}/${img}.webp`} alt={prod.titular} title={prod.titular} />
                        <article>
                            {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                            {prod.titular && <h3>{prod.titular}</h3>}
                        </article>
                        <Autor {...prod} />
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default Home1;
