import React from "react";
import { Link } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore'

import Autor from "../../Autor/Autor";

const Home3 = () => {
    const { articulos } = useFirestore(() => getArticulos());

    const filteredArticles = articulos.filter(e => e.home3 === "1" && e.activo === "1").sort((a, b) => b.date - a.date);
    const img = window.innerWidth >= 1024 ? "img2" : "img1";
    
    const firstArticle = filteredArticles.slice(0, 1);
    const remainingArticles = filteredArticles.slice(1, 7);

    return (
        <div className="home categoria3">
            <div className="container-categoria3">
                {firstArticle.map(prod => (
                    <Link to={`/${prod.categoria}/${prod.id}`} className="img-main" key={prod.id}>
                        <img src={`../../img/articulos/${prod.id}/${img}.webp`} alt={prod.titular} title={prod.titular} />
                        <article className="txt-main">
                            {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                            {prod.titular && <h3>{prod.titular}</h3>}
                            <Autor {...prod} />
                        </article>
                    </Link>
                ))}

                <div className="notas">
                    {remainingArticles.map(prod => (
                        <Link to={`/${prod.categoria}/${prod.id}`} key={prod.id} className="nota">
                            <img src={`../../img/articulos/${prod.id}/img1.webp`} alt={prod.titular} title={prod.titular} />
                            <div>
                                <article>
                                    {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                                    {prod.titular && <h3>{prod.titular}</h3>}
                                </article>
                                <Autor {...prod} />
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home3;
