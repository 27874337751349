import React, { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";
import NotaPrincipal from "./NotaPrincipal/NotaPrincipal";
import Relacionados from "./Relacionados/Relacionados";
import Nota from "./Notas/Nota";
import RegistrarmeBanner from "../RegistrarmeBanner/RegistrarmeBanner";

const Articulo = () => {
    useEffect(() => {
        logEvent(analytics, window.location.pathname);
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <section>
            <div className="articulo">
                <NotaPrincipal />
                <div className="container-contenido">
                    <div className="contenido">
                        <Nota />
                    </div>
                    <Relacionados />
                </div>
            </div>
            <RegistrarmeBanner />
        </section>
    );
}

export default Articulo;
