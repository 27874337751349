import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD8AqP3fhYNLW-jP3whcu7chlcuV8J9vto",
    authDomain: "guiagro-web.firebaseapp.com",
    projectId: "guiagro-web",
    storageBucket: "guiagro-web.appspot.com",
    messagingSenderId: "283875014239",
    appId: "1:283875014239:web:7c14dbe55852bf0bc54dea",
    measurementId: "G-5P50X2GVSN"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics();

export const db = getFirestore(app);

export const date = serverTimestamp();

