import React from "react";
import { Link } from 'react-router-dom';


const RegistrarmeBanner = () => {
    
    return(
        <div className="registrarme-banner" style ={ {backgroundImage: `url('../../img/registrarme.webp')` } }>
            <article>
                Suscribite y recibí las últimas noticias y actualizaciones directamente en tu email
            </article>
            <Link to={`/newsletter`} className="btn">
                SUSCRIBIRSE
            </Link>
        </div>
    )
}

export default RegistrarmeBanner