import React from "react";
import { format } from 'date-fns';

const Autor = ({ autor, date }) => {
    const formattedDate = date ? format(date.toDate(), "MMMM d, yyyy") : '';

    return (
        <article className="autor">
            {autor && <span>{autor.toUpperCase()}</span>}
            <br/>
            {date && <p>{formattedDate}</p>}
        </article>
    );
};

export default Autor;
