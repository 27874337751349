import React from "react";
import { useParams } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/firebase";
import Ads from "../../Ads/Ads";

const Nota = () => {
    const { articuloId } = useParams();
    const { articulos } = useFirestore(() => getArticulos());
    
    const articulo = articulos.find(articulo => articulo.id === articuloId);

    if (!articulo || !articulo.notas) {
        return <div style={{display:"none"}}></div>;
    }

    const { notas } = articulo;
    let notasRenderizadas = 0;

    const trackAdClick = (adTitle) => {
        logEvent(analytics, 'click', {
            event_category: 'Ad Click',
            event_label: adTitle
        });
    };

    return (
        <div>
            {Object.entries(notas)
                .sort(([notaKeyA], [notaKeyB]) => parseInt(notaKeyA.slice(4)) - parseInt(notaKeyB.slice(4)))
                .map(([notaKey, nota]) => (
                    nota && (
                        <div key={notaKey} className="container-nota">
                            <h2>{nota.titulo}</h2>
                            {nota.img === "1" && <img src={`../../img/articulos/${articuloId}/${notaKey}.webp`} alt={nota.titulo} title={nota.titulo} />}
                            {nota.txt && <article dangerouslySetInnerHTML={{ __html: nota.txt }} />}
                            <div className="ads">
                                {nota.ads && Object.entries(nota.ads)
                                    .sort(([adKeyA], [adKeyB]) => {
                                        const numA = parseInt(adKeyA.match(/\d+/)[0]);
                                        const numB = parseInt(adKeyB.match(/\d+/)[0]);
                                        return numA - numB;
                                    })
                                    .map(([adKey, ad]) => (
                                        ad && (
                                            <div key={`${notaKey}-${adKey}`} className="container-ad">
                                                <a href={ad.link} target="_BLANK" onClick={() => trackAdClick(ad.titulo)}>
                                                    <img src={`../../img/articulos/${articuloId}/${notaKey}-${adKey}.webp`} alt={ad.titulo} title={ad.titulo} />
                                                    <h4>{ad.titulo}</h4>
                                                    <button className="btn">SEE ON AMAZON</button>
                                                </a>
                                            </div>
                                        )
                                    ))}
                            </div>
                            {(++notasRenderizadas % 2 === 0) && <Ads key={`ads-${articulo.id}`} />}
                        </div>
                    )
                ))}
        </div>
    );
}

export default Nota;
