import React from "react";
import { Link } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore'

import Autor from "../../Autor/Autor";

const Home2 = () => {
    const { articulos } = useFirestore(() => getArticulos());

    const filteredArticles = articulos.filter(e => e.home4 === "1" && e.activo === "1").sort((a, b) => b.date - a.date).slice(0, 4);

    return (
        <div className="home categoria2">
            <div className="card-categoria2">
                {filteredArticles.map(prod => (
                    <Link to={`/${prod.categoria}/${prod.id}`} className="link" key={prod.id}>
                        <img src={`../../img/articulos/${prod.id}/img2.webp`} alt={prod.titular} title={prod.titular}/>
                        <article>
                            {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                            {prod.titular && <h3>{prod.titular}</h3>}
                        </article>
                        <Autor {...prod} />
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Home2;
