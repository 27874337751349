import React, { useLayoutEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import RegistrarmeBanner from "../RegistrarmeBanner/RegistrarmeBanner";
import Home1 from "./Home1/Home1";
import Home2 from "./Home2/Home2";
import Home3 from "./Home3/Home3";
import Home4 from "./Home4/Home4";
import Ads from "../Ads/Ads";

const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    logEvent(analytics, window.location.pathname);

    return(
        <section>
            <Home1 />
            <Home2 />
            <Ads />
            <Home3 />
            <Ads />
            <Home4 />
            <RegistrarmeBanner />
        </section>
    )
}

export default Home