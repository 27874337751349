import React, { useLayoutEffect } from "react";

import ContactoForm from "../ContactoForm/ContactoForm";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

const Contacto = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    logEvent(analytics, window.location.pathname);

    return(
        <section>
            <ContactoForm />
        </section>
    )
}

export default Contacto