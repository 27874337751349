import React, { useState, useEffect } from "react";
import { getAds } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

const Ads = () => {
    const [filteredAds, setFilteredAds] = useState([]);
    const { ads } = useFirestore(() => getAds());

    useEffect(() => {
        const filterAds = () => {
            const activeAds = ads.filter(ad => ad.activo === "1");
            const shuffledAds = shuffle(activeAds);
            setFilteredAds(shuffledAds.slice(0, 1));
        };

        if (ads.length > 0) {
            filterAds();
        }
    }, [ads]);

    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    return (
        <div>
            {filteredAds.map(prod => (
                <div className="container-adsbanner" key={prod.id}>
                    <a href={`${prod.link}`} target="_blank" rel="noopener noreferrer">
                    <video autoPlay muted loop >
                        <source src={`../../img/ads/${prod.id}.mp4`} type="video/mp4"/>
                    </video>
                    {/* <video src={`../../img/ads/${prod.id}.mp4`} alt={prod.titulo} title={prod.titulo} /> */}
                    </a>
                </div>
            ))}
        </div>
    );
};

export default Ads;
