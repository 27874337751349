import React, { useState, useRef, useEffect } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import swal from 'sweetalert';

const Registrarme = () => {
    const formRef = useRef();
    const navigate = useNavigate();

    const [suscriptor, setSuscriptor] = useState({
        date: new Date().toLocaleDateString('es-AR'),
        email: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        logEvent(analytics, window.location.pathname);
    }, []);

    const sendEmail = (e) => {
    
        emailjs.sendForm('contacto', 'newsletter', formRef.current, 'GytHru5BJGDf9347Z')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        };

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createSuscriptor = () => {
        addDoc(collection(db, "suscriptor"), { suscriptor })
            .then(() => {
                swal(
                    "¡GRACIAS POR SUSCRIBIRTE!",
                    `Vas a estar al día con los últimos artículos y novedades!`,
                    "success"
                );
                sendEmail();
                navigate('/');
            })
            .catch(error => {
                console.log(error);
                swal(
                    "¡Ups!",
                    `Algo salió mal`,
                    "error"
                );
            });
    };

    return (
        <section>
            <div className="registrarme">
                <div className="container-form">
                    <h4>Suscribite a</h4>
                    <h2>GUIAGRO</h2>
                    <article>
                        Recibí actualizaciones e información directamente en tu bandeja de entrada. Suscribite hoy y disfrutá de las últimas noticias y eventos importantes.
                        <br /><br />
                        Simplemente ingresá tu dirección de email a continuación para comenzar:
                    </article>
                    <form ref={formRef} onSubmit={handleSubmit(createSuscriptor)}>
                        <div>
                            <input className="input"
                                type="email"
                                name="email"
                                placeholder="EMAIL"
                                value={suscriptor.email}
                                {...register('email')}
                                onChange={(e) => setSuscriptor({ ...suscriptor, email: e.target.value })}
                            />

                            {suscriptor.email &&
                                <input type="submit" value="SUSCRIBIRSE" className="btn" title="suscribirse" />
                            }
                        </div>
                    </form>
                    <div className="img">
                        <img src={`../../img/superregistrame.webp`} alt="suscribirse" title="suscribirse" />
                    </div>
                </div>
                <div className="subs-details">
                    <article>
                        Al suscribirte, aceptás recibir correos electrónicos promocionales de nuestra parte. Podés desuscribirte en cualquier momento. Tu dirección de correo electrónico solo se utilizará con el fin de enviarte nuestro newsletter y no se compartirá con terceros.
                    </article>
                </div>
            </div>
        </section>
    );
};

export default Registrarme;
