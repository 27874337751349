import React from "react";

import { Link } from 'react-router-dom';

const LogoNav = () => {
    return (
        <Link to="/">
                <img src="../../img/logonav.png" alt="guiagro.com.ar" className="logo"/>
        </Link>
    )
}

export default LogoNav