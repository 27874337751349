import React from "react";

const Loading = () => {

    return (
        <div className='loading'>
            <img src="../img/loading.gif" alt="Loading..."/>
        </div>
    )
}

export default Loading