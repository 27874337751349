import React from "react";
import { useParams } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore';

import Autor from "../../Autor/Autor";
import Loading from "../../Assets/Loading";
import NotA404 from "../../Assets/NotA404";

const NotaPrincipal = () => {
    const { articuloId } = useParams();
    const { isLoading, articulos, error } = useFirestore(() => getArticulos());
    
    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <NotA404 />;
    }

    const articulo = articulos.filter(e => e.id === articuloId && e.activo === "1");

    return (
        <div>
            {articulo.map(prod => (
                <div className="articulo-head" key={prod.id}>
                    <div className="container-head">
                        <div className="container-img">
                            <img src={`../../img/articulos/${prod.id}/img1.webp`} alt={prod.id} title={prod.titular} />
                        </div>
                        <div className="container-info">
                            {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                            {prod.titular && <h2>{prod.titular}</h2>}
                            {prod.bajada && <article dangerouslySetInnerHTML={{ __html: prod.bajada }} />}
                            <Autor {...prod} />
                        </div>
                    </div>
                    <div className="container-txt">
                        {prod.txt && <article dangerouslySetInnerHTML={{ __html: prod.txt }} />}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NotaPrincipal;
