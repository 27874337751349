import React, { useState, useRef, useEffect } from "react";
import { setDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { getArticulos, getCategorias } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

import swal from 'sweetalert';

const Editando = () => {
    const form = useRef();
    const navigate = useNavigate();

    const { categorias } = useFirestore(() => getCategorias())
    const { articulos } = useFirestore(() => getArticulos())

    const [selectedArticulo, setSelectedArticulo] = useState(null);

    const [articulo, setArticulo] = useState({
        id: "",
        activo: "",
        titular: "",
        bajada: "",
        autor: "",
        date: "",
        txt: "",
        home1: "",
        home2: "",
        home3: "",
        home4: "",
        categoria: "",
        subcategoria: "",
        notas: {}
    });

    const cargarDatosArticulo = (articuloId) => {
        const selected = articulos.find((art) => art.id === articuloId);
        setSelectedArticulo(selected);
        setArticulo({
            ...articulo,
            ...selected,
            notas: selected.notas || {}
        });
    };

    useEffect(() => {
        if (selectedArticulo) {
            cargarDatosArticulo(selectedArticulo.id);
        }
    }, [selectedArticulo]);

    const { register } = useForm();

    const handleSubmitForm = (e) => {
        e.preventDefault(); // Evita que el formulario se envíe automáticamente
        createArticulo();
    };

    const createArticulo = async () => {
        try {
            await setDoc(doc(db, "articulos", selectedArticulo.id), {
                ...articulo,
            });
            swal("ARTICULO EDITADO", "No te olvides cargar las imágenes en la carpeta", "success");
            navigate('/');
        } catch (error) {
            console.error('Error al guardar el artículo en Firebase:', error);
            swal("¡Ups!", "Algo falló", "error");
        }
    };

    const agregarNota = () => {
        if (Object.keys(articulo.notas).length < 10) {
            const newNoteId = Object.keys(articulo.notas).length + 1;
            setArticulo(prevState => ({
                ...prevState,
                notas: {
                    ...prevState.notas,
                    [`nota${newNoteId}`]: {
                        id: newNoteId,
                        img: "",
                        titulo: "",
                        txt: "",
                        ads: {
                            ad1: { link: "", titulo: "" },
                            ad2: { link: "", titulo: "" },
                            ad3: { link: "", titulo: "" },
                            ad4: { link: "", titulo: "" }
                        }
                    }
                }
            }));
        }
    };

    const eliminarNota = (notaId) => {
        const { [notaId]: omit, ...restNotas } = articulo.notas;
        setArticulo(prevState => ({ ...prevState, notas: restNotas }));
    };

    const agregarAd = (notaId) => {
        const adCount = Object.keys(articulo.notas[notaId].ads).length;
        if (adCount < 4) {
            const newAdKey = `ad${adCount + 1}`;
            setArticulo(prevState => ({
                ...prevState,
                notas: {
                    ...prevState.notas,
                    [notaId]: {
                        ...prevState.notas[notaId],
                        ads: {
                            ...prevState.notas[notaId].ads,
                            [newAdKey]: { link: "", titulo: "" }
                        }
                    }
                }
            }));
        }
    };

    const eliminarAd = (notaId, adKey) => {
        const newAds = { ...articulo.notas[notaId].ads };
        delete newAds[adKey];
        setArticulo(prevState => ({
            ...prevState,
            notas: {
                ...prevState.notas,
                [notaId]: {
                    ...prevState.notas[notaId],
                    ads: newAds
                }
            }
        }));
    };

    return (
        <section>
            <div className="carga">
                <div className="container-form">
                    <h4>ACÁ ESTÁS</h4>
                    <h2>EDITANDO NOTAS</h2>

                    <form ref={form} onSubmit={(e) => handleSubmitForm(e)}>
                        <div className="frame">
                            <article>
                                <h3>ID ARTÍCULO</h3>
                            </article>

                            <select
                                type="text"
                                name="ARTICULOS"
                                placeholder="id"
                                value={selectedArticulo ? selectedArticulo.id : ""}
                                onChange={(e) => {
                                    const selectedId = e.target.value;
                                    setSelectedArticulo({ id: selectedId }); // Solo establece el ID por ahora
                                }}
                            >
                                <option value="" disabled hidden>ELEGÍ ID</option>
                                {articulos.map(prod =>
                                    <option key={prod.id}>{prod.id}</option>
                                )}
                            </select>

                            <input
                                type="text"
                                name="id"
                                placeholder="id"
                                value={articulo.id}
                                {...register("id")}
                                onChange={(e) => setArticulo({ ...articulo, id: e.target.value })}
                            />
                        </div>

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>CATEGORÍA ARTÍCULO</h3>
                                </article>
                                <select
                                    type="text"
                                    name="categoria"
                                    placeholder="categoria"
                                    value={articulo.categoria}
                                    {...register('categoria')}
                                    onChange={(e) => setArticulo({ ...articulo, categoria: e.target.value })}
                                >
                                    {categorias.map(cat =>
                                        <option key={cat.id}>{cat.id}</option>
                                    )}
                                </select>
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>SUBCATEGORÍA ARTÍCULO</h3>
                                </article>
                                <input
                                    type="text"
                                    name="subcategoria"
                                    placeholder="subcategoria"
                                    value={articulo.subcategoria}
                                    {...register('subcategoria')}
                                    onChange={(e) => setArticulo({ ...articulo, subcategoria: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>ESTADO ACTIVO</h3>
                                </article>
                                <input
                                    type="text"
                                    name="activo"
                                    placeholder="activo"
                                    value={articulo.activo}
                                    {...register('activo')}
                                    onChange={(e) => setArticulo({ ...articulo, activo: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>TITULO ARTÍCULO</h3>
                                </article>
                                <input
                                    type="text"
                                    name="titular"
                                    placeholder="titular"
                                    value={articulo.titular}
                                    {...register('titular')}
                                    onChange={(e) => setArticulo({ ...articulo, titular: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>BAJADA</h3>
                                </article>
                                <textarea
                                    type="text"
                                    name="bajada"
                                    rows="3"
                                    placeholder="bajada"
                                    value={articulo.bajada}
                                    {...register('bajada')}
                                    onChange={(e) => setArticulo({ ...articulo, bajada: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>AUTOR ARTÍCULO</h3>
                                </article>
                                <input
                                    type="text"
                                    name="autor"
                                    placeholder="autor"
                                    value={articulo.autor}
                                    {...register('autor')}
                                    onChange={(e) => setSelectedArticulo({
                                        ...articulo,
                                        autor: e.target.value
                                    })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>FECHA CREACIÓN ARTÍCULO</h3>
                                </article>
                                <input 
                                    type="date" 
                                    name="date" 
                                    value={articulo.date ? articulo.date.toDate().toISOString().substr(0, 10) : ""}                                    
                                    {...register('date')}
                                    onChange={(e) => {
                                        const inputDate = e.target.value; // Formato YYYY-MM-DD
                                        const [year, month, day] = inputDate.split('-'); // Dividir la cadena en año, mes y día
                                        const selectedDate = new Date(`${month}/${day}/${year}`); // Crear un objeto Date en formato MM/DD/YYYY
                                        if (!isNaN(selectedDate.getTime())) { // Verificar si es una fecha válida
                                            setArticulo({ ...articulo, date: Timestamp.fromDate(selectedDate) });
                                        } else {
                                            console.error('Fecha inválida:', inputDate);
                                        }
                                    }}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>TEXTO PRINCIPAL</h3>
                                </article>
                                <textarea
                                    type="text"
                                    name="txt"
                                    rows="6"
                                    placeholder="txt"
                                    value={articulo.txt}
                                    {...register('txt')}
                                    onChange={(e) => setArticulo({ ...articulo, txt: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>UBICACIÓN 1 HOME PRINCIPAL</h3>
                                </article>
                                <input
                                    type="text"
                                    name="home1"
                                    placeholder="NOTA PRINCIPAL. marcar con un 1 si sí"
                                    value={articulo.home1}
                                    {...register('home1')}
                                    onChange={(e) => setArticulo({ ...articulo, home1: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>UBICACIÓN 2 HOME PRIMER BLOQUE 4</h3>
                                </article>
                                <input
                                    type="text"
                                    name="home2"
                                    placeholder="PRIMER BLOQUE DE 4. marcar con un 1 si sí"
                                    value={articulo.home2}
                                    {...register('home2')}
                                    onChange={(e) => setArticulo({ ...articulo, home2: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>UBICACIÓN 3 HOME EL BLOQUE GRANDE QUE BAJA</h3>
                                </article>
                                <input
                                    type="text"
                                    name="home3"
                                    placeholder="EL BLOQUE QUE TIENE LA IMAGEN GRANDOTA QUE BAJA. marcar con un 1 si sí"
                                    value={articulo.home3}
                                    {...register('home3')}
                                    onChange={(e) => setArticulo({ ...articulo, home3: e.target.value })}
                                />
                            </div>
                        )}

                        {selectedArticulo && (
                            <div className="frame">
                                <article>
                                    <h3>UBICACIÓN 4 HOME SEGUNDO BLOQUE 4</h3>
                                </article>
                                <input
                                    type="text"
                                    name="home4"
                                    placeholder="EL ULTIMO BLOQUE DE 4. marcar con un 1 si sí"
                                    value={articulo.home4}
                                    {...register('home4')}
                                    onChange={(e) => setArticulo({ ...articulo, home4: e.target.value })}
                                />
                            </div>
                        )}

                        {Object.entries(articulo.notas)
                            .sort(([notaKeyA], [notaKeyB]) => {
                                const numA = Number(notaKeyA.replace("nota", ""));
                                const numB = Number(notaKeyB.replace("nota", ""));
                                return numA - numB; // Orden ascendente por número de nota
                            })
                            .map(([notaKey, nota]) => (
                                <div className="frame" key={notaKey}>
                                    <h3>Nota {notaKey.slice(4)}</h3>

                                    <input
                                        type="text"
                                        placeholder="IMG"
                                        value={nota.img}
                                        onChange={(e) => {
                                            const newNota = { ...nota, img: e.target.value };
                                            setArticulo(prevState => ({
                                                ...prevState,
                                                notas: {
                                                    ...prevState.notas,
                                                    [notaKey]: newNota
                                                }
                                            }));
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Título"
                                        value={nota.titulo}
                                        onChange={(e) => {
                                            const newNota = { ...nota, titulo: e.target.value };
                                            setArticulo(prevState => ({
                                                ...prevState,
                                                notas: {
                                                    ...prevState.notas,
                                                    [notaKey]: newNota
                                                }
                                            }));
                                        }}
                                    />

                                    <br /><br />
                                    <article>
                                        Para separar en párrafos usar {`<br/><br/>`} como si fuera el enter.
                                    </article>
                                    <textarea
                                        placeholder="Texto"
                                        rows="6"
                                        value={nota.txt}
                                        onChange={(e) => {
                                            const newNota = { ...nota, txt: e.target.value };
                                            setArticulo(prevState => ({
                                                ...prevState,
                                                notas: {
                                                    ...prevState.notas,
                                                    [notaKey]: newNota
                                                }
                                            }));
                                        }}
                                    />
                                    <button type="button" onClick={() => agregarAd(notaKey)} className="btn">Agregar Ad</button>
                                    <button type="button" onClick={() => eliminarNota(notaKey)} className="btn">Eliminar Nota</button>
                                    {Object.entries(nota.ads)
                                        .sort(([adKeyA], [adKeyB]) => {
                                            const numA = Number(adKeyA.replace("ad", ""));
                                            const numB = Number(adKeyB.replace("ad", ""));
                                            return numA - numB; // Orden ascendente por número de ad
                                        })
                                        .map(([adKey, ad]) => (
                                            <div key={adKey}>
                                                <h4>Ad {adKey.slice(2)}</h4>
                                                <input
                                                    type="text"
                                                    placeholder="Titulo"
                                                    value={ad.titulo}
                                                    onChange={(e) => {
                                                        const newAd = { ...ad, titulo: e.target.value };
                                                        setArticulo(prevState => ({
                                                            ...prevState,
                                                            notas: {
                                                                ...prevState.notas,
                                                                [notaKey]: {
                                                                    ...prevState.notas[notaKey],
                                                                    ads: {
                                                                        ...prevState.notas[notaKey].ads,
                                                                        [adKey]: newAd
                                                                    }
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Link"
                                                    value={ad.link}
                                                    onChange={(e) => {
                                                        const newAd = { ...ad, link: e.target.value };
                                                        setArticulo(prevState => ({
                                                            ...prevState,
                                                            notas: {
                                                                ...prevState.notas,
                                                                [notaKey]: {
                                                                    ...prevState.notas[notaKey],
                                                                    ads: {
                                                                        ...prevState.notas[notaKey].ads,
                                                                        [adKey]: newAd
                                                                    }
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <button type="button" onClick={() => eliminarAd(notaKey, adKey)} className="btn">Eliminar Ad</button>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        <button type="button" onClick={agregarNota} className="btn">Agregar Nota</button>

                        {!articulo ? "" :
                            <button type="submit" value="EDITAR" className="boton">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L277.3 424.9l-40.1 74.5c-5.2 9.7-16.3 14.6-27 11.9S192 499 192 488V392c0-5.3 1.8-10.5 5.1-14.7L362.4 164.7c2.5-7.1-6.5-14.3-13-8.4L170.4 318.2l-32 28.9 0 0c-9.2 8.3-22.3 10.6-33.8 5.8l-85-35.4C8.4 312.8 .8 302.2 .1 290s5.5-23.7 16.1-29.8l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                            </button>
                        }
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Editando;