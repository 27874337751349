import React, { useState, useRef } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

import swal from 'sweetalert'

const ContactoForm = () => {

    const form = useRef()

    const [contact, setContact] = useState({
        date: new Date().toLocaleDateString('es-AR'),
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        mensaje: ""
    });

    const sendEmail = (e) => {
    
    emailjs.sendForm('contacto', 'mail-contacto', form.current, 'GytHru5BJGDf9347Z')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createContact = () => {
        
        addDoc(collection(db, "contact"), {
            contact
        }).then(() => {
            swal(
                "¡GRACIAS POR CONTACTARTE!", 
                `Te vamos a responder lo antes posible. 
                ¡Saludos!`, 
                "success");
            sendEmail()
            navigate('/')
        }).catch(error => {
            console.log(error)
            swal(
                "¡Ups!", 
                `Algo falló`, 
                "error");
        })
    }
    
    return(
        <section>
            <div className="contacto">
                <div className="container-form">
                    <h4>ENVIANOS TU CONSULTA</h4>
                    <h2>CONTACTANOS</h2>
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="NOMBRE" 
                            value={contact.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setContact({...contact, nombre: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="nombre"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="text" 
                            name="apellido" 
                            placeholder="APELLIDO" 
                            value={contact.apellido}
                            {...register('apellido', { required: "El campo APELLIDO es obligatorio" })}
                            onChange={(e) => setContact({...contact, apellido: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="apellido"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="email" 
                            name="email" 
                            placeholder="EMAIL" 
                            value={contact.email}
                            {...register('email', { required: "El campo EMAIL es obligatorio" })}
                            onChange={(e) => setContact({...contact, email: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="number" 
                            name="telefono" 
                            placeholder="TELÉFONO" 
                            pattern="[0-9]+" 
                            value={contact.telefono}
                            {...register('telefono', { required: "El campo TELÉFONO es obligatorio" })}
                            onChange={(e) => setContact({...contact, telefono: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="telefono"
                            render={({ message }) => <p>{message}</p>}
                        />
                        
                        <textarea 
                            type="text" 
                            name="mensaje" 
                            rows="6" 
                            placeholder="MENSAJE" 
                            value={contact.message}
                            {...register('mensaje')}
                            onChange={(e) => setContact({...contact, mensaje: e.target.value})}
                        />
                        
                        { !contact
                            ? ""
                            : 
                            <button type="submit" value="ENVIAR" className="boton" onClick={()=> {}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L277.3 424.9l-40.1 74.5c-5.2 9.7-16.3 14.6-27 11.9S192 499 192 488V392c0-5.3 1.8-10.5 5.1-14.7L362.4 164.7c2.5-7.1-6.5-14.3-13-8.4L170.4 318.2l-32 28.9 0 0c-9.2 8.3-22.3 10.6-33.8 5.8l-85-35.4C8.4 312.8 .8 302.2 .1 290s5.5-23.7 16.1-29.8l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                            </button>
                        }
                    </form>
                </div>
                <div className="container-img">
                    <img src="../../img/logo.webp" alt="guiagro.com.ar"/>
                </div>
            </div>
        </section>
    )
}

export default ContactoForm