import React, { useState, useRef } from "react";
import { setDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { parseISO } from 'date-fns';
import { getCategorias } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

import swal from 'sweetalert';

const Carga = () => {
    const form = useRef();
    const navigate = useNavigate();

    const { categorias } = useFirestore(() => getCategorias())

    const [articulo, setArticulo] = useState({
        id: "",
        activo: "",
        titular: "",
        bajada: "",
        autor: "",
        date: "",
        txt: "",
        home1: "",
        home2: "",
        home3: "",
        home4: "",
        categoria: "",
        subcategoria: "",
        notas: {}
    });

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createArticulo = async () => {
        try {
            const selectedDate = parseISO(articulo.date);
            const timestamp = Timestamp.fromMillis(selectedDate.getTime());
            await setDoc(doc(db, "articulos", articulo.id), {
                ...articulo,
                date: timestamp
            });
            swal("CLIENTE CARGADO", "No te olvides cargar las imágenes en la carpeta", "success");
            navigate('/');
        } catch (error) {
            console.error('Error al guardar el artículo en Firebase:', error);
            swal("¡Ups!", "Algo falló", "error");
        }
    };

    const agregarNota = () => {
        if (Object.keys(articulo.notas).length < 10) {
            const newNoteId = Object.keys(articulo.notas).length + 1;
            setArticulo({
                ...articulo,
                notas: {
                    ...articulo.notas,
                    [`nota${newNoteId}`]: {
                        id: newNoteId,
                        img: "",
                        titulo: "",
                        txt: "",
                        ads: {
                            ad1: { link: "", titulo: "" },
                            ad2: { link: "", titulo: "" },
                            ad3: { link: "", titulo: "" },
                            ad4: { link: "", titulo: "" }
                        }
                    }
                }
            });
        }
    };

    const eliminarNota = (notaId) => {
        const { [notaId]: omit, ...restNotas } = articulo.notas;
        setArticulo({ ...articulo, notas: restNotas });
    };

    const agregarAd = (notaId) => {
    const adCount = Object.keys(articulo.notas[notaId].ads).length;
    if (adCount < 4) {
        const newAdKey = `ad${adCount + 1}`;
        setArticulo({
            ...articulo,
            notas: {
                ...articulo.notas,
                [notaId]: {
                    ...articulo.notas[notaId],
                    ads: {
                        ...articulo.notas[notaId].ads,
                        [newAdKey]: { link: "", titulo: "" }
                    }
                }
            }
        });
    }
};

    const eliminarAd = (notaId, adKey) => {
        const newAds = { ...articulo.notas[notaId].ads };
        delete newAds[adKey];
        setArticulo({
            ...articulo,
            notas: {
                ...articulo.notas,
                [notaId]: {
                    ...articulo.notas[notaId],
                    ads: newAds
                }
            }
        });
    };

    return (
        <section>
            <div className="carga">
                <div className="container-form">
                    <h4>ACÁ ESTÁS</h4>
                    <h2>CARGANDO NOTAS NUEVAS</h2>
                    <form ref={form} onSubmit={handleSubmit(createArticulo)}>
                        <div className="frame">
                            <article>
                                <h3>
                                    ID ARTÍCULO
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="id" 
                                placeholder="id" 
                                value={articulo.id}
                                {...register("id", { required: "El campo ID es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, id: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="id"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    CATEGORÍA ARTÍCULO
                                </h3>
                            </article>
                            <select 
                                type="text" 
                                name="categoria" 
                                placeholder="categoria" 
                                value={articulo.categoria}
                                {...register('categoria', { required: "El campo categoria es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, categoria: e.target.value})}
                            >
                            { categorias.map(cat =>
                            <option key={cat.id}>{cat.id}</option>    
                            )}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                name="categoria"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    SUBCATEGORÍA ARTÍCULO
                                </h3>
                            </article>
                        <input 
                            type="text" 
                            name="subcategoria" 
                            placeholder="subcategoria" 
                            value={articulo.subcategoria}
                            {...register('subcategoria', { required: "El campo categoria es obligatorio" })}
                            onChange={(e) => setArticulo({...articulo, subcategoria: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="subcategoria"
                            render={({ message }) => <p>{message}</p>}
                        />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    ESTADO ACTIVO
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="activo" 
                                placeholder="activo" 
                                value={articulo.activo}
                                {...register('activo', { required: "El campo ACTIVO es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, activo: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="activo"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    TITULO ARTÍCULO
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="titular" 
                                placeholder="titular" 
                                value={articulo.titular}
                                {...register('titular', { required: "El campo TITULAR es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, titular: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="titular"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    BAJADA
                                </h3>
                            </article>
                            <textarea 
                                type="text" 
                                name="bajada" 
                                rows="3" 
                                placeholder="bajada" 
                                value={articulo.bajada}
                                {...register('bajada', { required: "El campo BAJADA es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, bajada: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="bajada"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    AUTOR ARTÍCULO
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="autor" 
                                placeholder="autor" 
                                value={articulo.autor}
                                {...register('autor')}
                                onChange={(e) => setArticulo({...articulo, autor: e.target.value})}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    FECHA CREACIÓN ARTÍCULO
                                </h3>
                            </article>
                            <input 
                                type="date" 
                                name="date" 
                                value={articulo.date}
                                {...register('date', { required: "El campo FECHA es obligatorio" })}
                                onChange={(e) => setArticulo({...articulo, date: e.target.value})}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="date"
                                render={({ message }) => <p>{message}</p>}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    TEXTO PRINCIPAL
                                </h3>
                            </article>
                            <textarea 
                                type="text" 
                                name="txt" 
                                rows="6" 
                                placeholder="txt" 
                                value={articulo.txt}
                                {...register('txt')}
                                onChange={(e) => setArticulo({...articulo, txt: e.target.value})}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    UBICACIÓN 1 HOME PRINCIPAL
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="home1" 
                                placeholder="NOTA PRINCIPAL. marcar con un 1 si sí" 
                                value={articulo.home1}
                                {...register('home1')}
                                onChange={(e) => setArticulo({...articulo, home1: e.target.value})}
                            />
                        </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    UBICACIÓN 2 HOME PRIMER BLOQUE 4
                                </h3>
                                </article>
                                <input 
                                    type="text" 
                                    name="home2" 
                                    placeholder="PRIMER BLOQUE DE 4. marcar con un 1 si sí" 
                                    value={articulo.home2}
                                    {...register('home2')}
                                    onChange={(e) => setArticulo({...articulo, home2: e.target.value})}
                                />
                            </div>

                        <div className="frame">
                            <article>
                                <h3>
                                    UBICACIÓN 3 HOME EL BLOQUE GRANDE QUE BAJA
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="home3" 
                                placeholder="EL BLOQUE QUE TIENE LA IMAGEN GRANDOTA QUE BAJA. marcar con un 1 si sí" 
                                value={articulo.home3}
                                {...register('home3')}
                                onChange={(e) => setArticulo({...articulo, home3: e.target.value})}
                            />
                        </div>
                        
                        <div className="frame">
                            <article>
                                <h3>
                                    UBICACIÓN 4 HOME SEGUNDO BLOQUE 4
                                </h3>
                            </article>
                            <input 
                                type="text" 
                                name="home4" 
                                placeholder="EL ULTIMO BLOQUE DE 4. marcar con un 1 si sí" 
                                value={articulo.home4}
                                {...register('home4')}
                                onChange={(e) => setArticulo({...articulo, home4: e.target.value})}
                            />
                        </div>                        

                        {Object.entries(articulo.notas).map(([notaKey, nota]) => (
                            <div className="frame">
                            <div key={notaKey}>
                                <h3>Nota {notaKey.slice(4)}</h3>

                                <input
                                    type="text"
                                    placeholder="IMG"
                                    value={nota.img}
                                    onChange={(e) => {
                                        const newNota = { ...nota, img: e.target.value };
                                        setArticulo({
                                            ...articulo,
                                            notas: {
                                                ...articulo.notas,
                                                [notaKey]: newNota
                                            }
                                        });
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Título"
                                    value={nota.titulo}
                                    onChange={(e) => {
                                        const newNota = { ...nota, titulo: e.target.value };
                                        setArticulo({
                                            ...articulo,
                                            notas: {
                                                ...articulo.notas,
                                                [notaKey]: newNota
                                            }
                                        });
                                    }}
                                />
                                
                                <br/><br/>
                                <article>
                                    Para separar en párrafos usar {`<br/><br/>`} como si fuera el enter.
                                </article>
                                <textarea
                                    placeholder="Texto"
                                    rows="6"
                                    value={nota.txt}
                                    onChange={(e) => {
                                        const newNota = { ...nota, txt: e.target.value };
                                        setArticulo({
                                            ...articulo,
                                            notas: {
                                                ...articulo.notas,
                                                [notaKey]: newNota
                                            }
                                        });
                                    }}
                                />
                                <button type="button" onClick={() => agregarAd(notaKey)} className="btn">Agregar Ad</button>
                                <button type="button" onClick={() => eliminarNota(notaKey)} className="btn">Eliminar Nota</button>
                                {Object.entries(nota.ads).map(([adKey, ad]) => (
                                    <div key={adKey}>
                                        <h4>Ad {adKey.slice(2)}</h4>
                                        <input
                                            type="text"
                                            placeholder="Link"
                                            value={ad.link}
                                            onChange={(e) => {
                                                const newAd = { ...ad, link: e.target.value };
                                                setArticulo({
                                                    ...articulo,
                                                    notas: {
                                                        ...articulo.notas,
                                                        [notaKey]: {
                                                            ...articulo.notas[notaKey],
                                                            ads: {
                                                                ...articulo.notas[notaKey].ads,
                                                                [adKey]: newAd
                                                            }
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Titulo"
                                            value={ad.titulo}
                                            onChange={(e) => {
                                                const newAd = { ...ad, titulo: e.target.value };
                                                setArticulo({
                                                    ...articulo,
                                                    notas: {
                                                        ...articulo.notas,
                                                        [notaKey]: {
                                                            ...articulo.notas[notaKey],
                                                            ads: {
                                                                ...articulo.notas[notaKey].ads,
                                                                [adKey]: newAd
                                                            }
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                        <button type="button" onClick={() => eliminarAd(notaKey, adKey)} className="btn">Eliminar Ad</button>
                                    </div>
                                ))}
                            </div>
                            </div>
                        ))}
                        <button type="button" onClick={agregarNota} className="btn">Agregar Nota</button>


                        { !articulo
                            ? ""
                            : 
                            <button type="submit" value="ENVIAR" className="boton" onClick={()=> {}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L277.3 424.9l-40.1 74.5c-5.2 9.7-16.3 14.6-27 11.9S192 499 192 488V392c0-5.3 1.8-10.5 5.1-14.7L362.4 164.7c2.5-7.1-6.5-14.3-13-8.4L170.4 318.2l-32 28.9 0 0c-9.2 8.3-22.3 10.6-33.8 5.8l-85-35.4C8.4 312.8 .8 302.2 .1 290s5.5-23.7 16.1-29.8l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                            </button>
                        }

                    </form>
                </div>
            </div>
        </section>
    );
};

export default Carga;
